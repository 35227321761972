import styled from 'styled-components'

const Grid = styled.div`
  display: grid;
  grid-template-columns:
    1fr
    min(60ch, calc(100% - 64px))
    1fr;
  grid-column-gap: 32px;

  & > * {
    grid-column: 2;
    margin-bottom: 32px;
  }
`

export default Grid
