const COLORS = {
  text: 'black',

  imageFilterFrom: 'hsl(283deg 15% 23% / 0.5)',
  imageFilterTo: 'hsl(273deg 15% 23% / 0.5)',

  footerBackground: 'hsl(283deg 15% 23%)',
  footerLinks: 'hsl(173deg 54% 56%)',

  black: 'black',
  white: 'white',
}

const FONTS = {
  primary: 'Playfair Display',
  secondary: 'Lato',
}

// const SIZES = [8, 16, 24, 32]

export { COLORS, FONTS }
