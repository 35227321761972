import { FatalErrorBoundary } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'
import GlobalStyles from './components/GlobalStyles'
import IKProvider from './components/IKProvider/IKProvider'

import './index.css'

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodApolloProvider>
      <IKProvider>
        <Routes />
        <GlobalStyles />
      </IKProvider>
    </RedwoodApolloProvider>
  </FatalErrorBoundary>
)

export default App
