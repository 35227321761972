import styled from 'styled-components'

import Grid from 'src/components/Grid'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'

const MainLayout = ({ children }) => (
  <>
    <Grid>
      <Header />
    </Grid>
    <Grid as="main">{children}</Grid>
    <Wrapper>
      <Footer />
    </Wrapper>
  </>
)

export default MainLayout

const Wrapper = styled(Grid)`
  background-color: var(--color-footer-background);
`
