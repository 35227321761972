import styled from 'styled-components'

import VStack from 'src/components/VStack'

const Footer = () => (
  <Wrapper as="footer">
    <h2>Contact</h2>
    <address>
      <dl>
        <dt>Email</dt>
        <dd>
          <a href="mailto:research@musicaldetective.com">
            research@musicaldetective.com
          </a>
        </dd>
        <dt>Phone</dt>
        <dd>
          <a href="tel:+1-818-716-4188">(818) 716 4188</a>
        </dd>
        <dt>Fax</dt>
        <dd>
          <a href="tel:+1-818-716-4189">(818) 716 4189</a>
        </dd>
        <dt>Address</dt>
        <dd>
          <p>Musical Detective</p>
          <p>P.O. Box 5173</p>
          <p>West Hills, CA 91308-5173</p>
        </dd>
      </dl>
    </address>
    <p>© 2021 Ace's Choice Entertainment</p>
  </Wrapper>
)

export default Footer

const Wrapper = styled(VStack)`
  padding-top: 64px;
  gap: 40px;
  color: white;

  dl {
    font-family: 'Lato';
    font-style: normal;
  }

  dt {
    font-weight: 700;
  }

  dd:not(:last-child) {
    padding-bottom: 24px;
  }

  a {
    color: var(--color-footer-links);
  }
`
