import { routes, NavLink } from '@redwoodjs/router'
import styled from 'styled-components'
import { IKImage } from 'imagekitio-react'

import VStack from 'src/components/VStack'

const links = [
  { name: 'home' },
  { name: 'services' },
  { name: 'experience' },
  { name: 'about' },
  { name: 'contact us', href: 'contact' },
]

const Header = () => {
  return (
    <Wrapper as="header">
      <IKImage path="/sepia_48C7xzkHS.jpeg" />
      <H1>
        <B>Musical Detective</B> Music Research Services
      </H1>
      <nav>
        <UL>
          {links.map((link) => (
            <li key={link.name}>
              <StyledNavLink
                to={routes[link.href ? link.href : link.name]()}
                activeClassName="active"
              >
                {link.name}
              </StyledNavLink>
            </li>
          ))}
        </UL>
      </nav>
    </Wrapper>
  )
}

export default Header

// ------------------------

const Wrapper = styled(VStack)`
  align-items: center;

  padding-top: 32px;
`

const H1 = styled.h1`
  font-family: var(--font-secondary);
  font-size: 32px;
  font-weight: normal;

  text-align: center;
`

const B = styled.b`
  display: block;

  font-family: var(--font-primary);
  font-size: 48px;
  font-weight: 700;
`

const UL = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  padding-top: 16px;

  list-style: none;

  text-transform: uppercase;
`

const StyledNavLink = styled(NavLink)`
  color: var(--color-black);
  text-decoration: none;
`
